<template>
  <WithSpinner :isFetching="isFetching">
    <div
      v-if="product"
      class="column"
    >
      <div class="lab">
        <div class="lab__content container">
          <router-link
            to="/"
            class="lab__go-back"
          >
            <IconArrowBack />
            <span>{{ t("toMainPage") }}</span>
          </router-link>

          <div class="lab__info">
            <div class="lab__subtitle">
              {{ t("labs.virtualLab", { name: product.subsection.name }) }}
            </div>
            <div class="lab__title">
              {{ product.name }}
            </div>
          </div>
          <div
            v-if="product.has_workbook"
            class="lab__workbook"
          >
            <IconWorkbook class="lab__workbook-icon" />
            {{ t("labs.workbook") }}
          </div>
        </div>
        <picture>
          <img
            :src="product.app_img?.original"
            :alt="product.name"
            class="lab__background"
          />
        </picture>

        <div class="lab__subscribe-wrap">
          <div class="lab__subscribe">
            <!-- запустить -->
            <Tooltip
              v-if="canLaunch"
              position="top"
            >
              <template
                v-if="showLicenseDetails"
                #message
              >
                <LaboratoryDetails :license="license" />
              </template>

              <UiButton
                type="primary"
                class="lab__btn-play"
                @click="handleClickApp"
              >
                {{ t("labs.launch") }}
                <ElIcon class="el-icon--right">
                  <IconPlay />
                </ElIcon>
              </UiButton>
            </Tooltip>

            <!-- подписаться -->
            <template v-else>
              <UiButton
                type="primary"
                class="lab__btn-play"
                @click="handleSubscribeClick"
              >
                {{ t("labs.subscribe") }}
                <ElIcon class="el-icon--right">
                  <ShopCart />
                </ElIcon>
              </UiButton>

              <!-- демо -->
              <Tooltip
                v-if="product.demo_mode"
                position="top"
                class="lab__tooltip"
                :message="demoCounterTooltipText"
              >
                <UiButton
                  type="primary"
                  class="lab__btn-play"
                  :class="{ 'mobile': isMobileConst }"
                  :disabled="!Boolean(product.demo_counter) && !isMobileConst"
                  @click="isMobileConst ? handleClickApp() : handleClick()"
                >
                  {{ t("labs.demo") }}
                  <ElIcon class="el-icon--right">
                    <IconPlay />
                  </ElIcon>
                </UiButton>
              </Tooltip>
            </template>
          </div>
        </div>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="labs__terms"
        v-html="product.description"
      />
      <!-- eslint-enable -->
    </div>
  </WithSpinner>
  <MobileModal
    :isOpen="isOpen"
    @close="isOpen = false"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { ElIcon } from 'element-plus';
import { names } from '@/constants/routes';
import {
  getStorageItem,
  setStorageItem,
  catchErrorShow,
  pluralNoun,
  isMobile,
} from '@/utils';
import { useEventListener } from '@/libraries/useEventListener';
import { loginUser } from '@/services/userActions';
import WithSpinner from '@/components/WithSpinner.vue';
import UiButton from '@/components/Ui/VButton.vue';
import Tooltip from '@/components/Tooltip.vue';
import IconWorkbook from '@/components/icons/Workbook.vue';
import { getUserSubscriptions } from '@/router/Subscriptions/services';
import { SubscribeUser } from '@/router/Subscriptions/types';
import dayjs from '@/utils/dayjs';
import MobileModal from '@/components/MobileModal.vue';
import IconArrowBack from '@/components/icons/ArrowBack.vue';
import IconPlay from '@/components/icons/Play.vue';
import ShopCart from '@/components/icons/ShopCart.vue';
import services from '../services';
import LaboratoryDetails from './components/Details.vue';
import { LicenseState } from './constants';
import { License } from './types';

const { t } = useI18n();
const router = useRouter();

const props = defineProps({
  id: {
    type: Number,
    default: 0,
  },
});

const isMobileConst = isMobile();
const store = useStore();
const isFetching = ref<boolean>(true);
const product = ref<License | null>(null);
const isOpen = ref<boolean>(false);
const license = ref<SubscribeUser | null>(null);
const isUser = computed<boolean>(() => store.getters.isAuthed);
const canLaunch = computed(() => product.value?.active_for_user);
const hasActiveLicenseState = computed(
  () => Boolean(product.value?.license_state)
);

const hasOrgLicense = computed(
  () => product.value?.license_state === LicenseState.Organization
);

const isLicenseExpired = computed(
  () => {
    const now = dayjs();
    const expires = (license.value?.date_expiration
      ? dayjs(license.value.date_expiration)
      : null);
    const diff = expires
      ? expires.diff(now, 'day')
      : 0;
    return diff < 0;
  }
);

const showLicenseDetails = computed(
  () => hasActiveLicenseState.value
    && license.value
    && !hasOrgLicense.value
    && !isLicenseExpired.value
);

const demoCounterTooltipText = computed(
  () => t(
    'youHaveNLaunchesLeft',
    {
      nn: product.value?.demo_counter || 0,
    },
    pluralNoun(product.value?.demo_counter || 0),
  )
);

const handleSubscribeClick = () => {
  // Get from local storage and covert into Set for unique values
  const basket = new Set(getStorageItem<number[]>('basket') || []);
  if (product.value?.offer.id) {
    // Add offer id of product to select them in shop
    basket.add(product.value.offer.id);
  }
  // Update basket
  setStorageItem('basket', Array.from(basket.values()));

  // Go into shop
  router.push({ name: names.shop });
};

const handleClick = async () => {
  if (isUser.value) {
    try {
      if (!product.value?.demo_counter) {
        throw new Error();
      }
      const { url } = await services.getLaboratoryDemoLink(props.id);
      window.open(url, '_blank');
    } catch (err) {
      catchErrorShow(err, t);
    }
  } else {
    return loginUser();
  }
};

const handleClickApp = async () => {
  if (isMobileConst) {
    isOpen.value = true;
    return;
  }
  try {
    const { url } = await services.getAppLink(props.id);
    window.open(url, '_blank');
  } catch (error) {
    console.error(error);
  }
};

const refreshProductData = async () => {
  if (document.visibilityState === 'visible') {
    const refreshProduct = await services.getLaboratoryById(props.id);
    product.value = refreshProduct;
  }
};

const launchFunction = async () => {
  isFetching.value = true;
  try {
    let subscriptions;

    const requests = [];
    if (isUser.value) {
      requests.push(services.getLaboratoryById(props.id));
      requests.push(getUserSubscriptions());
    } else {
      requests.push(services.getPublicLaboratoryById(props.id));
    }

    [product.value, subscriptions] = await Promise.all(requests);

    license.value = subscriptions?.find(
      (
        subscription: SubscribeUser
      ) => subscription.offer_id === product.value?.offer.id
    ) || null;
  } catch (err) {
    catchErrorShow(err, t);
  }
  isFetching.value = false;
};

launchFunction();

useEventListener(document, 'visibilitychange', refreshProductData);
</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.lab {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;

  &__btn-play {
    font-size: 15px;
    font-weight: 700;
  }

  &__content {
    position: relative;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: end;
    padding-top: 53px;
    padding-bottom: 250px;
    z-index: 1;
    color: $colorText1;
  }

  &__go-back {
    padding: 8px 16px;
    border-radius: 52px;
    font-size: 20px;
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    background: $bgGrad1;
    border: 1px solid transparent;

    &:hover {
      color: white;
      background: $bgGradPrimary;
    }
    &:active {
      color: white;
      background: $bgGradPrimaryPressed;
    }

    & svg {
      width: 16px;
      height: 24px;
    }
  }

  &__info {
    padding: 24px 44px 18px;
    background: $color-white;
    width: fit-content;
    border-radius: 8px;
    box-shadow: 208px 109px 66px 0 #09293900;
    background: $bgGrad1;
  }

  &__subtitle {
    color: $color-primary-darkest;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba($colorText1, .5);
    line-height: 1;
  }

  &__title {
    font-size: 44px;
    font-weight: 700;
    color: $color-primary-darkest;
    text-align: right;
    line-height: 1;
    padding-top: 10px;
  }

  &__workbook {
    display: flex;
    width: fit-content;
    padding: 6px 16px;
    font-size: 16px;
    background: $color-peach;
    color: $color-white;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid #DBDBDB;

    &-icon {
      font-size: 24px;
    }
  }
}

.lab__background {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.lab__subscribe-wrap {
  padding: 40px;
}

.lab__subscribe {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
}

.labs__terms {
  max-width: 1000px;
  margin: 40px auto;
  & p,
  & li,
  & ul,
  & ol {
    margin: revert;
  }

  ul, ol {
    padding-inline-start: 40px;
  }
}

.labs__modal-title {
  font-size: 26px;
  font-weight: 400;
  text-align: center;
}

.labs__modal-content {
  display: grid;
  gap: 10px;
  justify-content: center;
}


@include media-breakpoint-down("md") {
  .lab__info {
    margin: 0;
    box-shadow: none;
    text-align: center;
    width: 100%;
    padding: 10px;
  }
  .lab__subtitle {
    font-size: 16px;
    border-bottom: none;
    padding-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .lab__title {
    font-size: 20px;
    text-align: center;
  }
  .lab__content {
    padding: 0;
  }
  .lab__background {
    position: relative;
  }
  .lab__subscribe-wrap {
    padding: 20px;
  }
  .lab__subscribe {
    display: grid;
    grid-template-columns: minmax(200px, 250px) min-content;
    gap: 20px;
  }

  .labs__terms {
    padding: 20px;
    margin: 0px;
    & > p:first-child {
      margin-top: 0px;
    }
  }
}

</style>
