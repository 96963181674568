<template>
  {{ t("license") }}
  <template v-if="isAutoRenewal">
    {{ t("auto-renewal") }} {{ formatDate(expires) }}
  </template>
  <template v-else>
    {{ t("expires") }} {{ formatDate(expires) }}
  </template>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUserLicenses } from '@/composition/useUserLicenses';
import { SubscribeOrg, SubscribeUser } from '@/router/Subscriptions/types';
import { formatDate } from '@/utils';

const { t } = useI18n();

const props = defineProps<{
  license: SubscribeUser | SubscribeOrg;
}>();

const { expires } = useUserLicenses(
  toRef(props, 'license')
);

const isAutoRenewal = computed(
  () => Boolean(props.license.is_auto)
);
</script>
