<template>
  <div class="block">
    <h2 class="block__title">
      <slot />
    </h2>

    <TransitionGroup
      tag="div"
      name="fade"
      class="category"
    >
      <LabsCategoryCard
        v-for="(card, index) in categories"
        :key="`CategoryCard${index}`"
        :card="card"
        @isMobileNotWorking="isOpen = true"
      />
    </TransitionGroup>

    <MobileModal
      :isOpen="isOpen"
      @close="isOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import MobileModal from '@/components/MobileModal.vue';
import { LabData } from '../types';
import LabsCategoryCard from './CategoryCard.vue';

defineProps<{
  categories: Array<LabData>;
}>();

const isOpen = ref<boolean>(false);

</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.block {
  gap: 20px;
}

.category {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@include media-breakpoint-down("md") {
  .category {
    gap: 20px;
  }
}
</style>
