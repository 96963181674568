<template>
  <div class="filter">
    <div class="filter__buttons">
      <button
        v-for="(button, index) in filters.values()"
        :key="`btn-${index}`"
        :class="['toggle__btn', { active: button?.active }]"
        type="button"
        @click="addActive(button.shortname)"
      >
        {{ button?.name }}
        <span>
          ({{ button.count }})
        </span>
      </button>
    </div>
    <SearchSwitch
      :value="filterWord || ''"
      :placeholder="t('search')"
      useInputEvent
      @input="handleSearchInput"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import SearchSwitch from '@/components/inputs/SearchSwitch.vue';
import { FilterButton } from '../types';

defineProps<{
  filters: Map<string, FilterButton>;
  filterWord?: string;
}>();

const emit = defineEmits<{
  (ev: 'update:group', key: string): void;
  (ev: 'update:search', type: string): void;
}>();

const { t } = useI18n();

// METHODS
const addActive = (key: string) => {
  emit('update:group', key);
};

const handleSearchInput = (val: string) => {
  emit('update:search', val);
};
</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.filter {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  position: relative;
}

.filter__buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

@include media-breakpoint-down("sm") {
  .filter {
    gap: 0;
  }
  .filter__buttons {
    width: 85%;
    overflow: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: block;
    scrollbar-width: none;
  }
  .filter__buttons::-webkit-scrollbar {
    display: none;
  }
  .toggle__btn {
    padding: 0 12px;
    line-height: 26px;
    opacity: 1;
    font-size: 11px;
    font-weight: 700;
    box-sizing: content-box;
    margin-bottom: 0;
    transition: .5s box-shadow, .5s border-color;
  }
}
</style>
