<template>
  <div class="container labs">
    <WithSpinner :isFetching="isFetching">
      <LabsFilter
        v-if="filterSections.size > 0"
        :filters="filterSections"
        @update:group="updateGroup"
        @update:search="updateSearchInput"
      />

      <LabsRecent :recent="recent" />

      <div
        v-if="isEmpty"
        class="labs__empty"
      >
        {{ t("labs.emptySearch") }}
      </div>
      <TransitionFadeGroup tag="div">
        <LabsCategory
          v-for="[label, categories] in filteredProductsArray"
          v-show="isFiltersNotActive || isActiveFilter(label)"
          :key="label"
          :categories="categories"
        >
          {{ categoryName(label) }}
        </LabsCategory>
      </TransitionFadeGroup>
    </WithSpinner>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';
import { names } from '@/constants/routes';
import { getStorageItem } from '@/utils/utils-storage';
import WithSpinner from '@/components/WithSpinner.vue';
import TransitionFadeGroup from '@/components/TransitionFadeGroup.vue';
import { catchErrorShow } from '@/utils/errors';
import LabsFilter from './components/Filter.vue';
import LabsRecent from './components/Recent.vue';
import LabsCategory from './components/Category.vue';
import { LabData, FilterButton } from './types';
import services from './services';

const { t } = useI18n();
const store = useStore();

// CREATED

const isUser = computed<boolean>(() => store.getters.isAuthed);
const products = ref<Array<LabData>>([]);

const isFetching = ref<boolean>(true);
const searchFilter = ref<string>('');
const filterSections = ref<Map<string, FilterButton>>(new Map());

const recentProductsIds = getStorageItem<string[]>('recent') || [];
const recent = computed<Array<LabData>>(
  () => {
    const list = products.value
      .filter((product) => recentProductsIds.includes(String(product.id)));
    return list;
  }
);

const isFiltersNotActive = computed<boolean>(
  () => !Array.from(filterSections.value.values()).some(
    (filter: FilterButton) => filter.active
  )
);

const categoryName = (label: string) => (label ? t(`labs.section.${label}`) : 'No name category');

const filteredProducts = computed<Map<string, LabData[]>>(
  () => products.value.reduce(
    (acc, cur) => {
      if (searchFilter.value && !cur.nameLower.includes(searchFilter.value)) {
        return acc;
      }
      if (!acc.has(cur.section.shortname)) {
        acc.set(cur.section.shortname, [cur]);
      } else {
        acc.get(cur.section.shortname).push(cur);
      }
      return acc;
    },
    new Map()
  )
);

const filteredProductsArray = computed(
  () => Array.from(filteredProducts.value)
);

const isEmpty = computed<boolean>(() => filteredProducts.value.size === 0);

const createFilterBtn = () => {
  filterSections.value = products.value.reduce((acc, cur: LabData) => {
    if (!acc.has(cur.section.shortname)) {
      acc.set(cur.section.shortname, {
        ...cur.section,
        active: false,
        count: computed(
          () => filteredProducts.value.get(cur.section.shortname)?.length || 0
        ),
      });
    }
    return acc;
  }, new Map());
};

const getProducts = async () => {
  isFetching.value = true;
  try {
    const data = isUser.value
      ? await services.getLaboratoriesList()
      : await services.getPublicLaboratoriesList();
    products.value = data.reduce((acc: LabData[], product: LabData) => {
      acc.push({ ...product, nameLower: product.name.toLowerCase() });
      return acc;
    }, []);

    if (products.value.length > 0) {
      createFilterBtn();
    }
  } catch (err) {
    catchErrorShow(err, t);
  }
  isFetching.value = false;
};

const updateGroup = (key: string) => {
  if (filterSections.value?.get(key)) {
    const elem: FilterButton = filterSections.value?.get(key);
    elem.active = !elem.active;
  }
};

const updateSearchInput = (searchValue: string) => {
  searchFilter.value = searchValue.trim().toLowerCase();
};

const isActiveFilter = (
  label: string
) => filterSections.value.get(label)?.active;

// LEAVE
onBeforeRouteLeave(async (to) => {
  if (to.name === names.laboratory && isUser.value) {
    const localData = localStorage.getItem('recent');
    let recentLabs = [];
    if (localData) {
      recentLabs = JSON.parse(localData);
      recentLabs = recentLabs.filter((labId: string) => labId !== to.params.id);
    }
    recentLabs.unshift(to.params.id);
    localStorage.setItem('recent', JSON.stringify(recentLabs.slice(0, 5)));
  }
});

getProducts();
</script>

<style lang="scss" scoped>
@import "@/assets/style/include.scss";

.labs {
  display: flex;
  flex-direction: column;
  padding: 54px 20px;
  background: $bgLight1;
}

.labs__empty {
  font-style: italic;
  font-size: 20px;
  color: hsl(223, 35%, 51%);
  margin-top: 60px;
  margin-bottom: 60px;
}

@include media-breakpoint-down("sm") {
  .labs {
    padding: 20px;
  }
}
</style>
