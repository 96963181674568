<template>
  <div class="vue-carousel">
    <div class="vue-carousel-box">
      <div
        class="vue-carousel__arrow vue-carousel__prev"
        :class="{ disabled: currentSlide === 0 }"
        @click="prev"
      >
        <ArrowLeft />
      </div>

      <Carousel
        ref="myCarousel"
        v-model="currentSlide"
        :breakpoints="breakpoints"
        @slideEnd="slideEnd"
      >
        <Slide
          v-for="(card, index) in list"
          :key="index"
        >
          <LabsRecentCard
            :card="card"
            class="carousel__slide"
          />
        </Slide>
      </Carousel>

      <div
        class="vue-carousel__arrow vue-carousel__next"
        :class="{ disabled: currentSlide === list.length - 1 }"
        @click="next"
      >
        <ArrowLeft />
      </div>
    </div>

    <div class="pagination">
      <div
        v-for="(isVisible, index) in pagination"
        :key="`pag-${index}`"
        class="pagination-item"
        :class="{ disabled: isVisible }"
        @click="goTo(index, isVisible)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, nextTick, onUnmounted } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import { LabData } from '@/router/Laboratories/types';
import ArrowLeft from '@/components/icons/CarouselLeft.vue';
import LabsRecentCard from './RecentCard.vue';

defineProps<{
  list: LabData[];
}>();

const currentSlide = ref(0);
const myCarousel = ref();
const pagination = ref<boolean[]>([]);
const breakpoints = ref();

const updPagination = () => {
  const scrollBox = myCarousel.value.$el;
  const items: HTMLElement[] = scrollBox.querySelectorAll('li.carousel__slide');
  nextTick(() => {
    pagination.value = [];
    Array.from(items).forEach((item) => {
      pagination.value.push(item.getAttribute('aria-hidden') === 'false');
    });
  });
};

onMounted(() => {
  breakpoints.value = {
    // 300 and up
    300: {
      itemsToShow: myCarousel.value.data.maxSlide.value > 0 ? 1.25 : 1,
      snapAlign: 'start',
    },
    642: {
      itemsToShow: 2,
    },
    888: {
      itemsToShow: 3,
    },
  };

  updPagination();
  window.addEventListener('resize', updPagination);
});

onUnmounted(() => {
  window.removeEventListener('resize', updPagination);
});

const goTo = (index: number, isVisible: boolean) => {
  if (isVisible) { return; }
  myCarousel.value.slideTo(index);
};

const slideEnd = () => {
  updPagination();
};

const prev = () => {
  const index = pagination.value.findIndex((x: boolean) => x);
  myCarousel.value.slideTo(index);
};
const next = () => {
  const index = pagination.value.findLastIndex((x: boolean) => x);
  myCarousel.value.slideTo(index);
};


</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.vue-carousel {

  &-box {
    display: flex;
    align-items: center;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 8px;

    &-item {
      padding: 6px;
      cursor: pointer;

      &:before {
        width: 10px;
        height: 10px;
        content: '';
        display: block;
        border: 1px solid $colorBlue1;
        transform: rotate(45deg);
        transition: background .2s;
      }

      &.disabled {
        cursor: auto;
        &:before {
          background: $colorBlue1;
        }
      }

      &:hover {
        &:before {
          background: $colorBlue1;
        }
      }
    }
  }

  .carousel {
    width: calc(100% - 88px);
  }

  &__arrow {
    height: 100px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.disabled {
      cursor: not-allowed;
      opacity: .5;
    }

    & svg {
      width: 44px;
    }

    &:hover {
      opacity: .8;
    }
  }
  &__next {
    transform: rotate(180deg);
  }

  .carousel__pagination-button {
    padding: 10px;
  }
}

@include media-breakpoint-down("sm") {

  .vue-carousel {

    .carousel {
      width: 100%;
    }

    &__arrow {
      display: none;
    }
  }
}
</style>
