<template>
  <div
    v-if="recent && recent.length > 0"
    class="recent"
  >
    <h2 class="block__title">
      {{ t("labs.recent") }}
    </h2>

    <Vue3Carousel
      class="recent__carousel"
      :list="recent"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { LabData } from '../types';
import Vue3Carousel from './Vue3Carousel.vue';

defineProps<{
  recent: LabData[];
}>();

const { t } = useI18n();
</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.recent {
  .block__title {
    margin: 32px 0 16px 0;
  }

  &__carousel {
    padding: 38px 0 10px;
    background: white;
    border-radius: 12px;
  }
}

@include media-breakpoint-down("sm") {
  .recent {

    &__carousel {
      padding: 12px;
    }
  }
}
</style>
