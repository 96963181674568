<template>
  <router-link
    :to="{ name: names.laboratory, params: { id: card.id } }"
    class="card"
  >
    <picture class="card__picture">
      <img
        :src="fallbackSrc"
        :srcset="fallbackSrcset"
        :alt="card.name"
        class="card__img"
      />
    </picture>
  </router-link>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue';
import { names } from '@/constants/routes';
import { imageCandidatesToSrcset } from '@/utils';
import { LabData } from '../types';

const props = defineProps<{
  card: LabData;
}>();
const appImg = toRef(() => props.card.app_img);
const fallbackSrc = computed(() => appImg.value['240x160']);
const fallbackSrcset = computed(() => imageCandidatesToSrcset({
  '1x': appImg.value['240x160'],
  '2x': appImg.value['480x320'],
}));
</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.card {
  display: block;
  border-radius: 14px;
  border: 3px solid $color-white;
  position: relative;
  max-width: 240px;
  width: 100%;
  transition: border-color $transition-speed;
}

.card__picture {
  height: 100%;
}

.card__img {
  object-fit: cover;
  height: 100%;
}
</style>
